<template>
  <div>
    <component :is="templateData === undefined ? 'div' : 'b-card'">
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form: Template Info Form -->
        <b-form
          v-if="templateData"
          class="p-2 mt-1"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <!-- Field: name -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                vid="name"
                name="name"
                rules="required"
              >
                <b-form-group
                  label="اسم"
                  label-for="name"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="name"
                    v-model="templateData.name"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: description -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                vid="description"
                name="description"
                rules="required"
              >
                <b-form-group
                  label="وصف"
                  label-for="description"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="description"
                    v-model="templateData.description"
                    input-id="description"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: style_id -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                vid="style_id"
                name="style_id"
                rules="required"
              >
                <b-form-group
                  label="النمط المعتمد"
                  label-for="style_id"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="templateData.style_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="style_ids"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="style_id"
                    @input="style_idChanged"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: client_id -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                vid="client_id"
                name="client_id"
                rules=""
              >
                <b-form-group
                  label="العميل"
                  label-for="client_id"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="templateData.client_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="client_ids"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="client_id"
                    @input="client_idChanged"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: header_id -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                vid="header_id"
                name="header_id"
                rules="required"
              >
                <b-form-group
                  label="رأس الصفحة"
                  label-for="header_id"
                  :state="getValidationState(validationContext)"
                  description="سيتم إعتماد هذه القيمة إفتراضيا."
                >
                  <v-select
                    v-model="templateData.header_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="header_ids"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="header_id"
                    @input="header_idChanged"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: footer_id -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <validation-provider
                #default="validationContext"
                vid="footer_id"
                name="footer_id"
                rules="required"
              >
                <b-form-group
                  label="ذيل  الصفحة"
                  label-for="footer_id"
                  :state="getValidationState(validationContext)"
                  description="سيتم إعتماد هذه القيمة إفتراضيا."
                >
                  <v-select
                    v-model="templateData.footer_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="footer_ids"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="footer_id"
                    @input="footer_idChanged"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: pages -->
            <b-col
              cols="12"
              md="12"
              lg="12"
            >
              <!-- ---------------------------- pages --------------------- -->
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  lg="12"
                >
                  <div class="d-flex">
                    <feather-icon
                      icon="FileText"
                      size="19"
                    />
                    <h4 class="mb-0 ml-50">
                      الصفحات
                      <!-- Add item to pages -->
                      <b-button
                        class="btn-icon rounded-circle btn-sm"
                        variant="primary"
                        style="width: 14px; height: 14px; padding: 0"
                        size="sm"
                        @click="addPages()"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="10"
                        />
                      </b-button>
                    </h4>
                  </div>
                </b-col>
                <b-col cols="12">
                  <hr class="my-2">
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-for="(item, i) in templateData.pages"
                  :id="item.id"
                  :key="i"
                  ref="row"
                  cols="12"
                  md="12"
                  lg="12"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="12"
                      lg="12"
                      style="margin: auto"
                    >
                      <span class="text-overline"> الصفحة {{ i + 1 }} </span>
                      <b-button
                        v-if="i > 0"
                        class="btn-icon"
                        variant="danger"
                        size="sm"
                        style="left: 20px; width: 18px; height: 18px; padding: 0"
                        @click="removePages(i)"
                      >
                        <feather-icon
                          icon="XIcon"
                          size="12"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <!-- Field: name -->
                      <validation-provider
                        #default="validationContext"
                        vid="name"
                        name="name"
                        rules="required"
                      >
                        <b-form-group
                          label="اسم الصفحة"
                          label-for="name"
                          :state="getValidationState(validationContext)"
                        >
                          <b-form-input
                            id="name"
                            v-model="item.name"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <!-- Field: description -->
                      <validation-provider
                        #default="validationContext"
                        vid="description"
                        name="description"
                        rules=""
                      >
                        <b-form-group
                          label="الوصف"
                          label-for="description"
                          :state="getValidationState(validationContext)"
                        >
                          <b-form-input
                            id="description"
                            v-model="item.description"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <!-- Field: layout_id -->
                      <validation-provider
                        #default="validationContext"
                        vid="layout_id"
                        name="layout_id"
                        rules="required"
                      >
                        <b-form-group
                          label="قالب الصفحة"
                          label-for="layout_id"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="item.layout_id"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :options="layouts"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="layout_id"
                            @input="layout_idChanged"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="6"
                      lg="6"
                    >
                      <!-- orientation -->
                      <validation-provider
                        #default="validationContext"
                        name="orientation"
                        rules="required"
                      >
                        <b-form-group
                          label="إتجاه الصفحة"
                          label-for="orientation"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="item.orientation"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :options="orientations"
                            :reduce="(val) => val.value"
                            :clearable="false"
                            input-id="orientation"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                      <!-- end orientation -->
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                      lg="6"
                    >
                      <!-- size -->
                      <validation-provider
                        #default="validationContext"
                        name="size"
                        rules="required"
                      >
                        <b-form-group
                          label="مقاس الصفحة"
                          label-for="size"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="item.size"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :options="sizes"
                            :reduce="(val) => val.value"
                            :clearable="false"
                            input-id="size"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                      <!-- end size -->
                    </b-col>

                    <b-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      <!-- Field: has_header -->
                      <validation-provider
                        #default="validationContext"
                        vid="has_header"
                        name="has_header"
                        rules="required"
                      >
                        <b-form-group
                          label="وجود رأس الصفحة"
                          label-for="has_header"
                          :state="getValidationState(validationContext)"
                        >
                          <b-form-checkbox
                            v-model="item.has_header"
                            switch
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- Field: page.header_id -->
                    <b-col
                      v-if="item.has_header"
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <validation-provider
                        #default="validationContext"
                        vid="page.header_id"
                        name="page.header_id"
                      >
                        <b-form-group
                          label="رأس الصفحة"
                          label-for="page.header_id"
                          description="سيتم إعتماد رأس التقرير في حال عدم الإختيار."
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="item.header_id"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :options="header_ids"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="page.header_id"
                          />

                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col
                      cols="12"
                      md="2"
                      lg="2"
                    >
                      <!-- Field: has_footer -->
                      <validation-provider
                        #default="validationContext"
                        vid="has_footer"
                        name="has_footer"
                        rules="required"
                      >
                        <b-form-group
                          label="وجود التذييل"
                          label-for="has_footer"
                          :state="getValidationState(validationContext)"
                        >
                          <b-form-checkbox
                            v-model="item.has_footer"
                            switch
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- Field: page.footer_id -->
                    <b-col
                      v-if="item.has_footer"
                      cols="12"
                      md="6"
                      lg="4"
                    >
                      <validation-provider
                        #default="validationContext"
                        vid="page.footer_id"
                        name="page.footer_id"
                      >
                        <b-form-group
                          label="ذيل الصفحة"
                          label-for="page.footer_id"
                          description="سيتم إعتماد ذيل التقرير في حال عدم الإختيار."
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="item.footer_id"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :options="footer_ids"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="page.footer_id"
                          />

                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                type="submit"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                {{ $t('save') }}
              </b-button>
              <b-button
                variant="outline-secondary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                {{ $t('cancel') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </component>
  </div>
</template>
<script>
import {
  BSidebar, BRow, BCol, BForm, BCard, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BButton, BFormCheckbox,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, onUnmounted } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import router from '@/router';
import templateStore from '../templateStore';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    vSelect,
    flatPickr,
    BCard,
    BRow,
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  methods: {

    style_idChanged(value) {
      this.templateData.style_id = value;
    },
    header_idChanged(value) {
      this.templateData.header_id = value;
    },
    footer_idChanged(value) {
      this.templateData.footer_id = value;
    },
    layout_idChanged(value) {
      this.templateData.layout_id = value;
    },
    client_idChanged(value) {
      this.templateData.client_id = value;
    },

    addPages() {
      this.templateData.pages.push({
        has_header: true,
        has_footer: true,
        orientation: 'portrait',
        size: 'A4',
      });
    },
    removePages(index) {
      this.templateData.pages.splice(index, 1);
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          store.dispatch('template/addTemplate', this.templateData)
            .then((response) => {
              this.$toast.success(response.data.message);
              router.push('/template').catch(() => { });
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status === 422) {
                  this.$toast.error(err.response.data.message);
                  this.$refs.refFormObserver.setErrors(err.response.data.errors);
                } else {
                  this.$toast.error(err.response.data.error);
                }
              }
            });
        }
      }).catch((err) => {
        console.log(err);
      });
    },

  },
  setup(props, { emit }) {
    const REPORT_STORE_MODULE_NAME = 'template';

    // Register template
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, templateStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME);
    });
    const blankTemplateData = {
      name: '',
      description: '',
      style_id: '',
      client_id: '',
      header_id: '',
      footer_id: '',
      pages: [
        {
          name: '',
          description: '',
          layout_id: '',
          has_header: true,
          has_footer: true,
          orientation: 'portrait',
          size: 'A4',
        },
      ],
    };

    const templateData = ref(JSON.parse(JSON.stringify(blankTemplateData)));
    const resettemplateData = () => {
      templateData.value = JSON.parse(JSON.stringify(blankTemplateData));
    };

    const style_ids = ref([]);
    let res = store.dispatch('template/fetchStyles')
      .then((response) => {
        const { data } = response.data;
        style_ids.value = data.map((c) => ({
          label: c.name, value: c.id, ...c,
        }));
      });
    const header_ids = ref([]);
    res = store.dispatch('template/fetchCompanyHeaders')
      .then((response) => {
        const { data } = response.data;
        header_ids.value = data.map((c) => ({
          label: c.name, value: c.id, ...c,
        }));
      });
    const footer_ids = ref([]);
    res = store.dispatch('template/fetchCompanyFooters')
      .then((response) => {
        const { data } = response.data;
        footer_ids.value = data.map((c) => ({
          label: c.name, value: c.id, ...c,
        }));
      });
    const layouts = ref([]);
    res = store.dispatch('template/fetchLayouts')
      .then((response) => {
        const { data } = response;
        layouts.value = data.map((c) => ({
          label: c.description, value: c.id, ...c,
        }));
      });
    const client_ids = ref([]);
    res = store.dispatch('template/fetchClients')
      .then((response) => {
        const { data } = response;
        client_ids.value = data.map((c) => ({
          label: c.name, value: c.id, ...c,
        }));
      });
    const sizes = [
      {
        label: 'A4',
        value: 'A4',
      },
      {
        label: 'A5',
        value: 'A5',
      },
    ];

    const orientations = [
      {
        label: 'عمودي',
        value: 'portrait',
      },
      {
        label: 'أفقي',
        value: 'landscape',
      },
    ];

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resettemplateData);

    return {
      templateData,
      orientations,
      sizes,
      style_ids,
      header_ids,
      footer_ids,
      layouts,
      client_ids,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-template-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
